@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');
/* for zone bar chart */
@import url(https://fonts.googleapis.com/css?family=Open+Sans); 

/* @tailwind base; */
@tailwind components;
@tailwind utilities;


* {
  font-family: "Roboto", sans-serif;
}
